import request from '@/core/services/api.services.request'

export function getAllCorder(params) {
  return request({
    url: '/order/list',
    method: 'get',
    params: params
  })
}

export function getAllProcessingorder(params) {
  return request({
    url: '/processing/order/list',
    method: 'get',
    params: params
  })
}

export function getCorder(order_id) {
  return request({
    url: '/order/view/' + order_id,
    method: 'get',
  })
}

export function getEditorder(order_id) {
  return request({
    url: '/order/edit/' + order_id,
    method: 'get',
  })
}

export function deleteCorder(order_id) {
  return request({
    url: '/order/delete/' + order_id,
    method: 'delete',
  })
}

export function cancelOrder(order_id) {
  return request({
    url: '/order/cancel/' + order_id,
    method: 'get',
  })
}
export function generateInvoice(order_id) {
  return request({
    url: '/invoice/generate/' + order_id,
    method: 'get',
  })
}

export function changeStatus(order_id, status) {
  return request({
    url: '/order/change/status/' + order_id + '/' + status,
    method: 'post',
  })
}

export function updateCorder(order_id) {
  return request({
    url: '/order/update/' + order_id,
    method: 'post',
  })
}

export function searchCustomer(keyword) {
  return request({
    url: '/order/search/customer/' + keyword,
    method: 'get',
  })
}

export function searchProduct(keyword, products) {
  return request({
    url: '/order/search/product/' + keyword,
    params: products,
    method: 'get',
  })
}

export function getCustomerAddress(customer_id) {
  return request({
    url: '/order/address/list/' + customer_id,
    method: 'get',
  })
}

export function getWebsite(customer_id) {
  return request({
    url: '/customer/website/' + customer_id,
    method: 'get',
  })
}

export function downloadPdf(id, type) {
  return request({
    url: '/pdf/create/' + id + '/' + type,
    method: 'get',
    responseType: 'blob',
  })
}

export function getTax() {
  return request({
    url: '/tax/list',
    method: 'get',
  })
}

export function getTaxById(product_id) {
  return request({
    url: '/product/tax/' + product_id,
    method: 'get',
  })
}

export function getOrderTerm() {
  return request({
    url: '/order/termlist',
    method: 'get',
  })
}

export function getShippingMethod() {
  return request({
    url: '/order/shipping/method/list',
    method: 'get',
  })
}

export function getShipVia() {
  return request({
    url: '/order/ship/via/list',
    method: 'get',
  })
}

export function getLowstockOrders(params) {
  return request({
    url: '/low/stock/order/list',
    method: 'get',
    params: params
  })
}

export function getShippingCost(store_id, shipping_id) {
  return request({
    url: '/order/shipping-cost/' + store_id + '/' + shipping_id,
    method: 'get',
  })
}

export function getAllorders(params) {
  return request({
    url: '/all/orders/list',
    method: 'get',
    params: params
  })
}

export function getDeliveryType() {
  return request({
    url: '/order/deliverytype/list',
    method: 'get',
  })
}

export function getCancelView(cancel_id) {
  return request({
    url: '/cancel/view/' + cancel_id,
    method: 'get',
  })
}

export function getCancelCreditMemoView(payment_id) {
  return request({
    url: '/cancel/credit/memo/view/' + payment_id,
    method: 'get',
  })
}

export function getCompleteOrder(params) {
  return request({
    url: '/complete/order/list',
    method: 'get',
    params: params
  })
}

export function getCancelorders(params) {
  return request({
    url: '/cancel/orders/list',
    method: 'get',
    params: params
  })
}

export function createOrderdirect(balance_amount, customer_id) {
  return request({
    url: '/order/payment/term/' + balance_amount + '/' + customer_id,
    method: 'get',

  })
}


export function getCancelorder(order_id) {
  return request({
    url: '/cancelorder/view/' + order_id,
    method: 'get',
  })
}

export function getLowStockOrder(order_id) {
  return request({
    url: 'low/stock/order/view/' + order_id,
    method: 'get',
  })
}

export function pickupOrder(order_id) {
  return request({
    url: '/delivery/pickup/order/' + order_id,
    method: 'get',
  })
}

export function deleteDocument(docId) {
  return request({
    url: '/order/delete/document/' + docId,
    method: 'delete',
  });
}

export function getAllUnpaidorder(params) {
  return request({
    url: 'unpaid/order/list',
    method: 'get',
    params: params
  })
}
export function getLastCredit(customer_id) {
  return request({
    url: '/customer/last-credit-payment/' + customer_id,
    method: 'get',
  })
}

export function getIncompleteOrder(customer_id) {
  return request({
    url: '/customer/incompleteorder/' + customer_id,
    method: 'get',
  })
}
