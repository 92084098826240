<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                 <!-- <el-row>
                                    <el-col :span="24" align="right">
                                      <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_dropdown">
                                        Actions
                                        <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                          <el-dropdown-item :command="1" >Print</el-dropdown-item>
                                          <el-dropdown-item :command="2" >Download</el-dropdown-item>
                                          <el-dropdown-item :command="3" >Send Email</el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </el-col>
                                  </el-row> -->
                                <el-row>
                                    <el-col :span="12" align="left">
                                        <span v-if="store_address.website_logo" v-loadimage="store_address.website_logo ? store_address.website_logo:''"  ></span>
                                    </el-col>
                                    <el-col :span="12" align="right">
                                            <b >
                                    <label class="mt-10">
                                      {{store_address.address?store_address.address:''}}, 
                                      {{store_address.city_details? store_address.city_details.city_name: ''}}, 
                                      {{store_address.state_details? store_address.state_details.state_name:''}} -{{store_address.post_code}}</label><br>
                                    <label>Phone: {{store_address.phone?store_address.phone:''}}</label><br>
                                    <label>Fax: {{store_address.fax?store_address.fax:''}}</label><br>
                                    <label>Email: {{store_address.email_address?store_address.email_address:''}}</label>
                                    </b>
                                    <br>
                                    </el-col>
                                  </el-row>
                                   <hr class="invoice-line">
                                  <el-row>                                     
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>PURCHASE BACK ORDER DETAILS</b></h2>
                                      </el-col>                                                                        
                                  </el-row>
                                   <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>PURCHASE ORDER #</b></span><span><b> {{view.po_number}}</b></span><br>
                                           <span><b>PURCHASE BACK ORDER #</b></span><span><b> {{view.back_order_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>SUPPLIER ID :</b></span><span><b> 00000{{view.supplier_id}}</b></span>
                                       </el-col>
                                  </el-row>
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO :</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name" >{{view.supplier.company_name}}</div>
                                          <div style="padding: 3px;" v-if="view.supplier &&(view.supplier.unit_no || view.supplier.street)">{{view.supplier? view.supplier.street:'' }}{{view.supplier.unit_no ? ', Unit No : '+view.supplier.unit_no :''  }} </div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.city">{{view.supplier.city? view.supplier.city.city_name : ''}}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.state">{{view.supplier.state? view.supplier.state.state_name + (view.supplier.postal_code ? ' - '+view.supplier.postal_code : ''):''}}</div>
                                      </el-col>
                                       <el-col :span="8" align="">
                                            .
                                      </el-col>
                                       <el-col :span="8" align="left">                                          
                                        <div class="billing-adr" style="padding-left:10px;" v-if="view.delivery_type == 'Delivery'">SHIP TO :</div>
                                         <div class="billing-adr" style="padding-left:10px;" v-else>PickUp :</div>
                                         <div style="padding: 3px;" v-if="view.store_details && view.store_details.website_name && view.delivery_type == 'Delivery'">{{view.store_details?view.store_details.website_name:''}}</div>
                                         <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name && view.delivery_type == 'PickUp'">{{view.supplier.company_name}}</div>
                                         <div style="padding: 3px;" v-if="view.address || view.unit_no">{{view.address}}{{view.unit_no ? ', Unit No : '+ view.unit_no:'' }}</div>
                                          <div style="padding: 3px;" v-if="view.city && view.city.city_name ">{{view.city? view.city.city_name : '' }}</div>
                                          <div style="padding: 3px;" v-if="view.state && view.state.state_name ">{{view.state.state_name +(view.post_code ? ' - '+view.post_code : '')}}</div>
                                      </el-col>
                                    </el-row>
                                     <div class="row">
                                      <div class="col-xl-12 ">
                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >DATE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >REQUEST DATE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >PURCHASER</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SHIP VIA</div></th>
                                                    <th aria-colindex="2" role="cell"><div >F.O.B</div></th>
                                                    <th aria-colindex="2" role="cell"><div >TERMS</div></th>
                                                </tr>
                                                <tr class="billing-cl3">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{format_date(view.created_at)}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{format_date(view.request_date)}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{view.confirm_by}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{view.ship_via}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{view.fob}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{view.term?view.term.term_name:''}}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                          <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Product Name</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Total Qty</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Back Order</div></th>
                                                </tr>
                                                   <tr class="billing-cl3" v-for="item in view.backorder_products" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.product_supplier?item.product_supplier.mfg_id:''}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.product_item?item.product_item.name:''}} - <b>{{item.product_item.sku}}</b></div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{item.quantity}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.back_quantity}}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                      </div>
                                     </div>

                            </div>
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getBackPurchaseorder, downloadPdf} from "@/api/purchaseorder";
import SendEmail from '@/components/SendEmail'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[], 
      store_address : [],     
      view_type: 'purchaseorder'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Purchase Orders", route: "/purchase-order/list" }, 
      { title: "View Orders" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
   fetchData() {
      this.loading = true
      getBackPurchaseorder(this.$route.params.backorder_id).then(response => {  
        console.log(response.data.data);    
        this.view = response.data.data 
        this.store_address = response.data.data.store_details     
        this.loading = false
      });
    },
     printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    }, 
     handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.poId, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'purchaseorder.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.supplier;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }
    }    
   }
   
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;
      margin-top: 30px;
      margin-left: 10px;
    }
    @media print {
       
        .print-section, .download-btn, .subheader, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    

}
 
</style>