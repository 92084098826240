<template>
 <el-card shadow="never" :body-style="{ margin: '20px' }" v-loading="loading"  >
        <div slot="header" class="modal-header">
          <span class="modal-title" >Send Email</span>
          <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
        </div>
        <el-form :model="form"  ref="form" label-position="left" label-width="150px" :disabled="form.submiting">
        <el-form-item label="Email" prop="email">
            <el-input type="email" v-model="form.email" placeholder="Email" auto-complete="off"></el-input>
            <span class="form-err" v-if="form.errors.has('email')" >{{form.errors.get('email')}}</span>
        </el-form-item>
        <el-form-item label="Customer Name" prop="customer_name">
            <el-input v-model="form.customer_name" @input.native="capitalize" placeholder="Name"></el-input>
            <span class="form-err" v-if="form.errors.has('customer_name')" >{{form.errors.get('customer_name')}}</span>
        </el-form-item>
        <el-form-item label="Subject" prop="subject">
            <el-input v-model="form.subject" placeholder="Subject" auto-complete="off" ></el-input>
            <span class="form-err" v-if="form.errors.has('subject')" >{{form.errors.get('subject')}}</span>
        </el-form-item>
      
        <el-form-item>
            <el-button type="primary" @click.prevent="submitForm" :icon="form.submiting?'el-icon-loading':''">Send</el-button>
          <!-- <el-button @click.prevent="reset()" >Reset</el-button> -->
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import Form from '@/core/services/api.form.services'
import { globalEvents } from '@/utils/globalEvents'
import { getCorder } from "@/api/order";
export default {
  name: 'create-event',
   props: {
    customer: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default() {
        return {}
      }
    },
    order_id: {
      type: Number,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      loading : false,
      form : new Form({
        email : null,
        customer_name : null,
        subject:null,
        is_attachment : false,
        type : null,
      }),
      order_details:[]
    }
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
    submitForm() {
      this.loading = true;
      const formURL = 'email/send/'+this.order_id+"/"+this.type;
      this.form.post(formURL).then((response) => {  
        globalEvents.$emit('mailsend', 'success');     
        this.$emit('close');
        if(response.status){
            this.$showResponse('success', response.message);
        }

        this.loading=false;
      }).catch((error) => {
        console.log(error);
        this.loading=false;
      })
    },
    orderDetails(order_id){
      this.loading = true;
      getCorder(order_id).then((response) => {
        this.order_details = response.data.data
        if(this.order_details.invoice){
          this.form.subject = this.order_details.invoice.invoice_number
        }else{
          this.form.subject = this.order_details.order_number
        }
        this.loading = false;
      });
    }
  },
  created() {
    if(this.type == 'orderConfirm' || this.type == 'invoice'){
      this.orderDetails(this.order_id);
    }
  },
  mounted(){
    if(this.customer){
      if(this.type == 'return_product'){
        this.type = 'Return Product'
      }
        this.form.type = this.type;
        this.form.email = this.customer.email_address;
        this.form.customer_name = this.customer.first_name + " " + (this.customer.last_name !=null?this.customer.last_name:'');
    }
  }
}

</script>
<style scoped>
  .modal-header{
    padding: 0px;
    border:0;
  }
  .modal-header .modal-title{
    font-size: 16px;
    font-weight: bold;
  }
</style>