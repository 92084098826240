import request from '@/core/services/api.services.request'

export function getAllCorder() {
    return request({
      url: '/order/list',
      method: 'get',
    })
  }

  export function getPurchaseorder(po_id) {
    return request({
      url: '/purchase-order/view/'+po_id,
      method: 'get',
    })
  }
  export function getReceivePurchaseorder(po_id) {
    return request({
      url: 'receive/purchase-order/view/'+po_id,
      method: 'get',
    })
  }

  export function downloadPdf(id,type) {
    return request({
      url: '/pdf/create/' + id +'/' + type,
      method: 'get',
      responseType: 'blob',
    })
  }

  export function getStoreAddress(store_id) {
    return request({
      url: '/store/address/'+store_id,
      method: 'get',
    })
  }
  export function getProductList(supplier_id) {
    return request({
      url: '/purchase-supplier/product/'+supplier_id,
      method: 'get',
    })
  }

  export function getPurchaseorderById(po_id) {
    return request({
      url: '/purchase/orderId/'+po_id,
      method: 'get',
    })
  }

  export function deletePurchaseorder(purchase_id) {
    return request({
      url: '/purchaseorder/delete/'+purchase_id,
      method: 'delete',
    })
  }

  export function getRequestPO(params) {
    return request({
      url: '/request/purchase/order',
      method: 'get',
      params: params
    })
  }

  export function getPurchaseBackorder(params) {
    return request({
      url: '/purchase/back/orders',
      method: 'get',
      params: params
    })
  }

  export function getAllPurchaseOrder(params) {
    return request({
      url: '/receive/order/list',
      method: 'get',
      params: params
    })
  }

  export function getAllReceiveOrders(params) {
    return request({
      url: '/receive/purchase-order/list',
      method: 'get',
      params: params
    })
  }

  export function getPurchaseOrderProduct(supplier_id) {
    return request({
      url: '/request/purchase/product/'+supplier_id,
      method: 'get',     
    })
  }

  export function getBackPurchaseorder(backorder_id) {
    return request({
      url: '/purchase/backorder/view/'+backorder_id,
      method: 'get',
    })
  }

  export function getLowStockProduct(supplier_id) {
    return request({
      url: '/lowstock/purchase/product/'+supplier_id,
      method: 'get',     
    })
  }

  export function getLowStockOrderProduct(supplier_id) {
    return request({
      url: '/lowstock-order/product/'+supplier_id,
      method: 'get',     
    })
  }
    
export function getPurchaseOrderId() {
  return request({
    url: '/purchaseOrderId',
    method: 'get'
  })
}

export function getCancelPurchaseorder(cancel_id) {
  return request({
    url: '/purchase/cancelOrder/list/'+cancel_id,
    method: 'get'
  })
}

export function getPurchaseBackOrder(po_id) {
  return request({
    url: '/purchase/backOrder/list/'+po_id,
    method: 'get'
  })
}


export function getReceivePurchaseOrderView(po_receive_id) {
  return request({
    url: 'receive/purchaseOrder/view/'+po_receive_id,
    method: 'get',
  })
}
export function getPurchaseOrderWaiting(params) {
  return request({
    url: '/purchaseOrder/waiting/list',
    method: 'get',
    params: params
  })
}

export function getPurchaseOrderProcessing(params) {
  return request({
    url: '/purchaseOrder/processing/list',
    method: 'get',
    params: params
  })
}


